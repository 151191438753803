<template>
  <div class="header">
    <div class="logo"><img src="@/assets/imgs/logo.png" alt=""></div>
    <div class="right">
      <a href="https://t.me/MW_MetaUtopia">
        <img src="@/assets/imgs/icon1.png" style="width: 12px;" alt="">
      </a>
      <a href="https://discord.gg/HK2CRqhPfQ">
        <img src="@/assets/imgs/icon2.png" style="width: 13px;" alt="">
      </a>
      <a href="https://twitter.com/mw_metautopia">
        <img src="@/assets/imgs/icon3.png" style="width: 13px;" alt="">
      </a>
      <a href="https://tttttt.me/Nic_MetaUtopiacs">
        <img src="@/assets/imgs/icon4.png" style="width: 10px;" alt="">
      </a>
      <div style="margin-left: 10px;" @click="openZendesk">
        <img src="@/assets/imgs/icon5.png" style="width: 11px;" alt="">
      </div>
    </div>
    <div class="navIcon">
      <img v-show="!show" src="@/assets/imgs/icon_nav.png" @click="show = true" style="width: 22px;" alt="">
      <img v-show="show" src="@/assets/imgs/icon_close.png" @click="show = false" style="width: 17px;" alt="">
    </div>
    <van-popup
      v-model="show"
      position="left"
      :get-container="getContainer"
      :style="{ width: '76px', height: '100%'}"
    >
      <div class="nav">
        <a href="#/" class="item" @click="show = false">
          <div class="img">
            <img src="@/assets/imgs/nav_home.png" alt="">
          </div>
          <div class="text">HOME</div>
        </a>
        <a class="item" @click="toAbout">
          <div class="img">
            <img src="@/assets/imgs/nav_about.png" alt="">
          </div>
          <div class="text">ABOUT US</div>
        </a>
        <a href="#/cities" class="item">
          <div class="img">
            <img src="@/assets/imgs/nav_city.png" alt="">
          </div>
          <div class="text">CITIES IN UTOPIA</div>
        </a>
        <a href="https://meta-utopia.gitbook.io/meta-utopia/introduction/welcome" class="item">
          <div class="img">
            <img src="@/assets/imgs/nav_gitbook.png" alt="">
          </div>
          <div class="text">GITBOOK</div>
        </a>
        <a href="#/roadmap" class="item">
          <div class="img">
            <img src="@/assets/imgs/nav_roadmap.png" alt="">
          </div>
          <div class="text">OUR ROADMAP</div>
        </a>
        <a href="#/faq" class="item">
          <div class="img">
            <img src="@/assets/imgs/nav_faq.png" alt="">
          </div>
          <div class="text">FAQ</div>
        </a>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      show: false
    }
  },
  methods: {
    openZendesk() {
      zE('webWidget', 'show');
      zE('webWidget', 'open');
    },
    getContainer() {
      return document.querySelector('.main');
    },
    toAbout() {
      this.show = false
      if (this.$route.path !== '/') {
        this.$router.push({
          path: '/',
          query: {
            tag: 'aboutUs'
          }
        })
      } else {
        this.$emit('handlePage')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.header{
  height: 49px;
  background: linear-gradient(180deg, #09161C 0%, #09161C 0%, #273842 100%, #273842 100%);
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 3000;
  .logo{
    width: 140px;
    margin-left: 32px;
  }
  .navIcon{
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
  .right{
    display: flex;
    align-items: center;
    a{
      margin-left: 10px;
    }
    a:first-child{
      margin-left: 0;
    }
  }
}
.van-popup{
  background: rgba(15, 40, 43, 0.71);
  backdrop-filter: blur(20px);
  padding-top: 49px;
}
.nav{
  padding-top: 20px;
  text-align: center;
  .item{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    .img{
      width: 39px;
    }
    .text{
      font-family: IBMPlexSans, Verdana;
      font-size: 12px;
      color: #9E9E9E;
    }
  }
  .item:last-child{
    margin-bottom: 0;
  }
}
</style>
