<template>
  <div class="join">
    <Header/>
    <div class="main">
      <div class="logo"><img src="@/assets/imgs/logo_join.png" alt=""></div>
      <div class="container">
        <div class="title">Join our Community</div>
        <div class="receive">
          <p>to receive the latest news and updates</p>
          <p>from the project!</p>
        </div>
        <div class="form">
          <div class="item">
            <input v-model="form.email" type="text" placeholder="Enter your email"/>
          </div>
          <div class="item">
            <input v-model="form.name" type="text" placeholder="Enter your name"/>
          </div>
        </div>
        <div class="consider">I consider myself a...</div>
        <div class="gender">
          <div class="item">
            <div class="img"><img src="@/assets/imgs/boy.png" alt=""></div>
            <div class="text">Boy</div>
            <div class="select" @click="handleSelect(0)">
              <img v-show="!form.sex" src="@/assets/imgs/select.png" alt="">
            </div>
          </div>
          <div class="item">
            <div class="img"><img src="@/assets/imgs/girl.png" alt=""></div>
            <div class="text">Girl</div>
            <div class="select" @click="handleSelect(1)">
              <img v-show="form.sex" src="@/assets/imgs/select.png" alt="">
            </div>
          </div>
        </div>
        <div class="info">
          <p>By clicking JOIN you agree to your personal</p>
          <p>data being stored and used to receive more</p>
          <p>information via email about our products/</p>
          <p>services, events, news and offers.</p>
        </div>
        <div class="btn" @click="handleSubmit">Join</div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import { submitEmail } from '@/api'

export default {
  name: "Join",
  components: { Header },
  data() {
    return {
      form: {
        email: '',
        name: '',
        sex: 0
      }
    }
  },
  methods: {
    handleSelect(val) {
      this.form.sex = val
    },
    handleSubmit() {
      if (this.form.email === '') {
        this.$toast('Enter your email');
      } else if (this.form.name === '') {
        this.$toast('Enter your name');
      } else {
        submitEmail(this.form).then(res => {
          if (res.success) {
            this.$toast.success('Operation is successful')
          } else {
            this.$toast.fail(res.msg)
          }
        }).catch(() => {})
      }
    }
  }
}
</script>

<style lang="less" scoped>
.join{
  .main{
    margin-top: 49px;
    position: relative;
    color: #fff;
    background: url("~@/assets/imgs/join_bg.png") no-repeat;
    background-size: 100% 100%;
    padding: 38px 0;
    .logo{
      width: 209px;
      margin: 0 auto;
    }
    .container{
      margin: 18px 26px 0 26px;
      border-radius: 20px;
      background: linear-gradient(180deg, #0B1A20 0%, #131D23 100%);
      box-shadow: 0 3px 15px 8px rgba(0, 0, 0, 0.3),inset 0 0 20px 3px rgba(0, 255, 183, 0.36);
      border: 3px solid #0bac9c;
      padding: 25px 22px 40px 22px;
      .title{
        font-family: Montserrat-Bold, Verdana;
        font-size: 16px;
        font-weight: bold;
        line-height: 23px;
        text-transform: uppercase;
        background-image: -webkit-linear-gradient(top, #0bedad, #0ba191);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .receive{
        font-family: Montserrat-Regular, Verdana;
        font-size: 12px;
        line-height: 20px;
        margin: 18px 0 20px 0;
      }
      .form{
        .item{
          margin-bottom: 10px;
          input{
            display: block;
            width: 100%;
            height: 38px;
            line-height: 38px;
            border-radius: 16px;
            background: linear-gradient(180deg, rgba(195,195,195,0.34) -1%, rgba(195,195,195,0.08) 100%);
            box-sizing: border-box;
            border: 2px solid #D8D8D8;
            backdrop-filter: blur(31px);
            box-shadow: inset 0 3px 20px 0 #FFFFFF;
            padding-left: 13px;
            font-family: Montserrat-Regular, Verdana;
            font-size: 14px;
            color: #fff;
          }
          input::-webkit-input-placeholder {
            color: #eee;
          }
        }
        .item:last-child{
          margin-bottom: 0;
        }
      }
      .consider{
        font-family: Montserrat-Bold, Verdana;
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
        text-transform: capitalize;
        color: #C3C3C3;
        margin: 33px 0 20px 0;
      }
      .gender{
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        .item{
          width: calc(50% - 5px);
          height: 138px;
          border-radius: 16px;
          background: linear-gradient(180deg, rgba(195,195,195,0.34) -1%, rgba(195,195,195,0.08) 100%);
          box-sizing: border-box;
          border: 2px solid #D8D8D8;
          backdrop-filter: blur(31px);
          box-shadow: inset 0 3px 20px 0 #FFFFFF;
          padding-top: 8px;
          display: flex;
          align-items: center;
          position: relative;
          .img{
            width: 85px;
          }
          .text{
            font-family: Montserrat-Bold, Verdana;
            font-size: 16px;
            font-weight: bold;
            line-height: 23px;
            text-transform: uppercase;
            margin-left: -10px;
          }
          .select{
            position: absolute;
            width: 24px;
            height: 24px;
            background: #12191C;
            border: 2px solid #C3C3C3;
            box-shadow: inset 0 4px 10px 0 #000000;
            top: 8px;
            right: 6px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
              width: 14px;
            }
          }
        }
      }
      .info{
        font-family: Montserrat-Regular, Verdana;
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
